<template>
    <div class="reset-pass">
      <div class="loading-box"
        v-if="loading"
      >
        <i class="el-icon-loading icon-ele"></i>
        <p class="title">重置密码中</p>
        <span>请耐心等待</span>
      </div>
      <div class="success-box"
        v-else
      >
        <img class="img-ele" src="../../assets/img/resetPassSuccess.png" alt="">
        <p>重置成功</p>
        <el-button class="btn-login" round type="primary" @click="$router.push({name: 'login'})">立刻登录</el-button>
        <p class="bottom">
          <span class="second">{{timeOut}}s</span>
          <span>自动跳转</span>
        </p>
      </div>
    </div>
</template>

<script>
  import { resetPwd } from '@/api/system/user'
  export default {
    name: 'resetPass',
    data() {
      return {
        loading: false,
        timeOut: 3,
      }
    },
    created(){
      let params = this.$route.query;
      this.loading = true;
      resetPwd(params).then((res) => {
        this.loading = false;
        setInterval(() => {
          this.timeOut--;
          if(this.timeOut == 0){
            this.$router.push({path: '/login'});
          }
        },1000);
      });
    },
  }
</script>

<style lang="less" scoped>
.reset-pass {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .success-box {
    text-align: center;
    .btn-login {
      width: 300px;
    }
    .second {
      color: blue;
    }
  }
  .loading-box {
    text-align: center;
    .icon-ele {
      font-size: 40px;
    }
    .title {
      font-size: 22px;
      font-weight: bold;
    }
  }
}
</style>
